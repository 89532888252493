:root {
    /*Margins & Paddings*/
    --padding:25px;
    --margin: 1em;
    --vertical-padding: 25px; /* must NOT be a percentage value */
    --vertical-margin: 2em;  /* must NOT be a percentage value */
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1100px;
     /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --header-min-height: 80px;
    --base-font-family: 'Open Sans', sans-serif;
    --alternative-font-family: 'Roboto Slab', sans-serif;
    /*Colors*/
    --map-marker-color: 000;
}

@import "/css/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/css/basic_styling.css";
@import "/theme/intendit/css/modules/flex-grid.css";   
@import "/css/slick.css";
@import "/css/slick-theme.css";
@import "/css/menu.v1.2.css";
@import "/css/magnific-popup.css";
@import "/theme/intendit/css/modules/hoverboxes.css";   
@import "/fonts/Quicksand/all.css";
@import "/fonts/Open Sans/all.css";  
@import "/fonts/Roboto Slab/all.css";  


@import "/css/animate.css"; 


.tjanster .box {background:#81c1cb; border-radius: 5px;  }
.referenser .box {background:#9ad6c3; border-radius: 5px;  }
 .box h3 {padding: 0 0 10px; font-size:1.45em; }



.eio {
    background: #fff none repeat scroll 0 0;
    height: auto;
    padding: 15px;
    position: fixed;
    right: 0;
    top: 115px;
    width: 90px;
    z-index: 20;
}

/*------------------------------------------------------------------------------------------------ 
GENERAL ----------------------------------------------------------------------------------------*/
body {font-family: var(--base-font-family); color: #252525; font-weight:300; line-height:1.35;  
    /*background: url('/thumbs/1920x1080r/theme/intendit/graphics/bg.jpg') center top/cover no-repeat fixed;*/ }

/*wrappers*/
.wrapper {position:relative; margin-top:var(--fixed-header-height); min-height: calc(100vh - var(--header-min-height));  }  
.content-wrapper {padding: 40px 0 50px; } 
.container {width:100%; }
.container.sml {max-width: 900px;}
.container.lrg {max-width: 1300px;}

/*basic styles*/
a, a:hover {color:#404040; font-weight:300; text-decoration: none;}
.section a, .section a:hover, .section a:focus {color:inherit;}
p {margin:0.2em 0 1.2em;}
strong {font-weight:500;}
iframe {max-width:100%;}
 
/*------------------------------------------------------------------------------------------------ 
LISTS & TABLES ---------------------------------------------------------------------------------*/
.section ul {list-style:none; padding: 0; margin: 0.5em 0 1em;}


 .ommarieviksel ul li:before {content: "\f00c"; margin:0 0.5em 0 0; font-family: FontAwesome;}*/

/*table
table {margin:30px 0; font-size:95%; width:100%;} tbody {width:100%;}
table tr {width:100%; border-bottom: 1px solid #e4e4e4;}
table tr td {margin: 0; padding:5px 30px 5px 0; vertical-align: top; border:none; } 
table tr td:last-child {padding:5px 0px 5px 0;}*/

/*egna klasser*/
.spaltX2 {column-count: 2; column-gap: 50px; text-align:left !important;} /*Delar upp i 2 spalter*/
.shadow {box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);} 
.publishdate {color:#909090; font-size:95%; display:block; clear:both;  }
.record { border-bottom:1px solid #e4e4e4;}
 
/*------------------------------------------------------------------------------------------------ 
IMAGES -----------------------------------------------------------------------------------------*/
img {max-width: 100%; height: auto;}
figure {margin:0; }
.flex > * > img {flex: 0 0 auto;}
.flex > * > img:not([class]) {width: 100%;}
/*artikelbild*/
figure.page-image {float:right; max-width:40%; height:auto; margin: 0 0 2em 2em;}
figure.page-image img {display:block; width:100%; height:auto;}
figure.page-image figcaption {font-style:italic; color:#606060;} /*bildtext inne på sidor*/ 
/*video bakgrundsbild*/
.listing-video {background-size:cover; background-position:center center; min-height:200px; }

/*filelist*/ 
a.file {display:block;}

/*------------------------------------------------------------------------------------------------ 
BUTTONS------------------------------------------------------------------------------------------*/
.button.button, .readmore {margin:0; padding: 6px 0 8px; display:block; width:100%; text-align: center; border:none; border-radius:2px; 
background: rgba(0, 0, 0, 0.1);  transition:background 0.5s ease-in 0s; }
.contact-form .button.button {background: rgba(255, 255, 255, 0.1);}  
.readmore {max-width:100px;} 
.button.button:hover, .readmore:hover { background:rgba(0, 0, 0, 0.3);} 


/*------------------------------------------------------------------------------------------------ 
ICONS ------------------------------------------------------------------------------------------*/
.fa-icon {height:20px; width: auto; vertical-align:middle !important; }
.round {background: rgba(0, 0, 0, 0.5); border-radius: 50%; display: inline-block; height: 40px; width: 40px; margin: 0 10px 10px 0; }
.round .fa-icon {fill:#fff; padding: 9px 0; height:40px; width: auto; display:block; margin: 0 auto; }
.round .fa-icon[aria-label="Icon depicting mobile"] {padding: 3px 0; }
.toptext .round {display:block; }
.box .round {display:block; margin:0 auto 1em; height: 80px; width: 80px;} 
.box .round .fa-icon { padding: 20px 0; height:80px; }
.listing-video .fa-icon {height: 40px; fill:#fff;}

/*scrollup*/
.scrollup {opacity:0; position:fixed; bottom:30px; right:0px; z-index:2; transition:opacity 0.5s ease-in 0s;}
body.scrolled .scrollup {opacity:0.7; transition:opacity 0.5s ease-in 0s;}  body.scrolled .scrollup:hover {opacity:1; transition: opacity 0.5s ease-in 0s; }
.scrollup .fa-icon {fill:#fff; height:80px; width:auto; }

/*arrow*/
.arrow.round {background: #101010 ; display: block; height: 80px;  left: 50%; margin: -40px 0 0 -40px; position: absolute; width: 80px; z-index: 5;} 
.arrow.round .fa-icon { fill:#fff; padding: 2px 0 0; height:80px; }


/*------------------------------------------------------------------------------------------------ 
FONTS ----------------------------------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {letter-spacing:0; line-height:1.1; padding: 0; margin: 0; font-weight:400; font-family: var(--alternative-font-family); }
h1{font-size: 2.6em; margin: 0 0 0.5em; font-weight:700;  /*text-transform:uppercase;*/}
h2{font-size: 1.65em;}
h3{font-size: 1.3em;}  
h4, h5, h6 {font-size: 1.3em; }

h2.highlight {font-size:1.5em; padding: 0 0 0.5em; color:#909090; font-weight:400; }

/*------------------------------------------------------------------------------------------------ 
TOPPEN ----------------------------------------------------------------------------------------*/
.toptext { position:absolute; right:10px; top:90px; z-index:1; display:none; } /*display none by default*/
.imagetop {min-height:50vh; background-position: center center; background-size:cover; position: relative;}


/*------------------------------------------------------------------------------------------------ 
STARTSIDA ----------------------------------------------------------------------------------------*/

/*slick slider*/
.slick-wrapper {min-height:70vh; position:relative; flex-wrap: wrap; }
.slick-track, .slick-substitute {display:flex; flex-direction:row;}
.slick-slider, .slick-substitute > div, .slick-slide {min-height:70vh; margin:0; }
.slick-slider, .slick-substitute, .slick-substitute > div { width:100%; height:100%; top:0; left:0; z-index:0;}
.slick-slide, .slick-substitute > div {background-size:cover; background-position: center center; display:flex !important;}   
.slick {visibility: hidden;} .slick-initialized {visibility: visible;} .slick-list, .slick-track {height:100%;}

/*Slick title*/ 
.slick-title {width:100%; text-align:center; font-size: 1.5em; line-height:1.1em; padding: 30px 0; }
.slick-title h2 {font-size: 2em;}
 
/*Starttext - aktivera nedersta för starttext inuti slick-wrapper*/ 
.starttext {
    background: rgba(255, 255, 255, 0.6) none repeat scroll 0 0;
    
    color: #101010;
    padding: 30px 0;
    text-align: center;
    width: 100%;
    z-index: 0;
}
.starttext h1 {margin: 0 0 10px; font-size: 3em;}
.starttext p {margin-bottom:0; font-size: 1.15em; line-height:1.1; font-weight:400; } 
 .slick-slider, .slick-substitute > div { position:absolute; width:100%; height:100%;  top:0; left:0; z-index:-1; } 

/*Slick arrows
.slick-prev, .slick-next {width:100px; height:100px; border:none;position:absolute; z-index:1; }  
.slick-prev {left:30px;} .slick-next {right:30px;}
.slick-prev:before, .slick-next:before { font-size:100px; color:#fff; opacity:0.9; font-family: Quicksand, sans-serif; }
.slick-prev:before {content:"<";} .slick-next:before {content:">";}*/

/*Slick dots
.slick-dots {margin:0; bottom: 10px;  }
.slick-dots li button {background:#000; border-radius:50%; width:14px; height:14px; opacity:0.6; transition: opacity 0.5s ease-in 0s;  }
.slick-dots > .slick-active > button {opacity:1; }
.slick-dots li button:before {color: transparent; content: "";}*/

/*----------------------Boxar-------------------------------*/
.boxes {padding: 2.5em 0;  position:relative; z-index:1; background:#fff;  }
.intro-section .boxes { border-top: 20px solid #26c7f1; }

.box {/*box-shadow: 0px 0px 0px 1px #e4e4e4;*/}
.box h2 {}
.box-text {}
.box-text p:last-of-type { margin:0.2em 0 0.5em;}

/*------------------------------------------------------------------------------------------------ 
ONEPAGE SPECIFIC---------------------------------------------------------------------------------*/
.section {width:100%; padding: 100px 0; position:relative;}
.section h1 {text-align:center; }
.section.full {min-height: calc(100vh - var(--fixed-header-height));}
.banner-section {min-height:75vh; }
.banner-section h1 {font-size:4em;  }
 
/*------------------------------------------------------------------------------------------------ 
CONTENT----------------------------------------------------------------------------------------*/

/*gallery*/
.image-gallery, .logotype-gallery {padding: 2em 0 0; margin-top:1em; border-top: 1px solid #d0d0d0; }
.gallery-image {box-shadow: 1px 1px 0 0 #e4e4e4;} .gallery-image:nth-child(5n + 5) {box-shadow: 0px 1px 0 0 #e4e4e4;}
.gallery-image a {display:block; }
.gallery-image img {padding:20px; display: block;}
.thumb-title {font-size:90%; padding: 0 20px 20px;  }  

/*logo gallery*/
.logotype-image {min-height: 70px; } 
.logotype-image div, .logotype-image a {text-align:center; width: 100%; flex:0 0 auto; display:block;} 
.logotype-image img {height:auto;} .standing  {width:40%; } .squarish  {width:40%; } .landscape  {width:55%; }.longlandscape {width:70%; }.extralonglandscape {width:100%; }

/*magnific styling*/ 
.mfp-bg {background: #000; opacity:0.9;}  .mfp-arrow {top:40%; } 
.mfp-arrow, .mfp-arrow:before, .mfp-arrow-left:before, .mfp-arrow-right:before,.mfp-arrow:after, .mfp-arrow-left:after, .mfp-arrow-right:after {border:none ; margin-left:0; margin-right:0;  }
.mfp-arrow-left:before {content:"<"; } .mfp-arrow-right:before {content:">"; }
.mfp-arrow-left:before, .mfp-arrow-right:before {height:100px; width:100px; color:#fff; font-size:5.5em; font-family: 'Quicksand', sans-serif; opacity:0.9;}
.mfp-arrow-left {margin-left:20px; }  .mfp-arrow-right {margin-right:20px; } 
button.mfp-close { font-size: 4em; margin:0 -2px 0 0; opacity: 0.9; font-family: Quicksand; }
  
/*------------------------------------------------------------------------------------------------ 
FOOTER & KONTAKT--------------------------------------------------------------------------------*/
/*kontaktbanner & formulär*/
.boltforms-row label, .boltforms-row button {font-weight:400;  margin-bottom: 0.2em;}
.boltforms-row input, .boltforms-row textarea {width:100%; color:#606060;  }
.contact-form .boltforms-row, .contact-banner .boltforms-row:nth-child(n+4) {width:100%;}

/*karta*/
.map-canvas {height: 35vh; margin-top:2em;}
.map-canvas img {max-width: none;}
.markerLabels{ overflow:visible !important;}
.fa-map-marker{ background: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22%23var(--map-marker-color)%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1152%20640q0-106-75-181t-181-75-181%2075-75%20181%2075%20181%20181%2075%20181-75%2075-181zm256%200q0%20109-33%20179l-364%20774q-16%2033-47.5%2052t-67.5%2019-67.5-19-46.5-52l-365-774q-33-70-33-179%200-212%20150-362t362-150%20362%20150%20150%20362z%22%2F%3E%3C%2Fsvg%3E');
background-size: cover; padding: 10px; background-position: center; }

/*footer*/
footer {padding: 50px 0 120px; width:100%; background:#000;}
footer, footer a, footer a:hover,footer a svg {color:#b0b0b0; font-weight:400; fill:#b0b0b0;}
footer h4 { padding: 0 0 5px; font-size:1.3em; } 
footer .round {background: rgba(255, 255, 255, 0.2);}

/*intendit*/
.created-by {text-align: center; padding: 8px 0; background:#101010;}
.created-by p, .created-by a {color:#707070; font-size: 0.9em; letter-spacing: 0.5pt; margin: 0;}

/*------------------------------------------------------------------------------------------------ 
HEADER ----------------------------------------------------------------------------------------*/
header {position:fixed; background:#fff;width:100%; top:0; z-index:10; min-height:var(--fixed-header-height); font-family: var(--base-font-family);}
body.scrolled header {}

/*Logotype*/
.logo {padding:10px 15px; box-sizing:border-box; } 
.logo a {padding:0; display:block; font-size:2em; } 
.logo img {max-height:80px; width:auto; max-width:calc(100vw - 100px); display:block;  }

/*Standard meny + hovers*/
nav {background:inherit; } 
header nav ul.menu > li {padding: 0 10px; margin:0; border-right:1px solid #e4e4e4;}
nav ul.menu > li:last-child {border:none; }
nav ul.menu li a { padding: 4px 5px; margin:0; letter-spacing:0; /*transition:color 0.5s ease-in 0s;*/}
nav ul.menu li a:hover, nav ul.menu li.active a { }
nav ul.menu li.dropdown > a:after { content: " \f107"; display: inline-block; font-family:FontAwesome; font-size: 1em; margin: 0 0 0 5px; vertical-align: middle;}
nav .menu .dropdown ul  {border:none; padding: 10px 0; left:20px; }
nav ul.menu > li:last-child > ul { left: auto;right: 0; text-align:right; }

/*Mobile menu - fixed header*/
@media (max-width: var(--breakpoint-menu)){  
header { box-shadow:none; flex-wrap:nowrap; } 
nav ul.menu { background:rgba(255, 255, 255, 0.9); }
nav ul.menu.show { max-height:calc(100vh - var(--fixed-header-height)); padding: 10px 0; }  /*räknar höjd - fixed header*/
nav ul.menu li, .mobile-menu nav ul.menu li a, .mobile-menu nav .menu .dropdown ul {border:none; background:none; }
nav .menu .dropdown ul {padding:0; left:0px;} 
nav ul.menu li.dropdown > a:after {display:none; }
/*menu icon*/
nav #menu-icon {align-self: flex-start; padding: 20px 20px 20px 0;}
nav #menu-icon span {border-radius:3px; border: 2px solid #000;}
}
 

/*------------------------------------------------------------------------------------------------ 
MEDIA QUERIES ----------------------------------------------------------------------------------*/

/*----------------------------Allt under 1300px----------------------------*/
@media (max-width: 1300px) 
{  
/* Standard meny - tight it up*/ 
nav ul.menu li {padding:0 5px; }      
nav ul.menu li a {}   
    
/*Container*/
.container.lrg {width: 96%;}   
header.container.lrg {width: 100%;}   
    
}
   
/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{
/*Startsida*/ 
.starttext {}
.starttext h1 {font-size: 2.6em;
    margin: 0 0 5px;}
.starttext p {} 
    
    
.eio { 
    padding: 10px;
    width: 70px;
}
    
/*Containers*/
.container {width: 96%;}  
header.container {width: 100%;}  
    
/*sektion*/   
.section { padding: 70px 0; }  
.banner-section h1 {font-size:3em;}  
}

/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
    
h1 { font-size: 2.3em;}   
    
.banner-section {
    min-height: 50vw;
}   
.arrow.round .fa-icon {
    fill: #fff;
    height: 70px;
    padding: 2px 0 0;
}
.arrow.round {
     height: 70px;
     margin: -35px 0 0 -35px;
     width: 70px;
 }   
    
    .boxes {margin-left:0; margin-right:0; }   
/*Startsida*/ 
.starttext {padding-top:1.2em; padding-bottom:1.2em; }
.starttext h1 {font-size:2.4em;}
.starttext p {} 
    
/*Fontsize*/ 
body {font-size: 0.95em; }
    
/*Slider & topbild height */ 
.slick-wrapper, .slick-slider, .slick-substitute > div, .slick-slide, .imagetop {min-height:55vw;}
    
/*gallerithumbs*/
.gallery-image:nth-child(3n+3) {box-shadow: 0 1px 0 0 #e4e4e4;}
.gallery-image:nth-child(5n+5) {box-shadow: 1px 1px 0 0 #e4e4e4;}  
    
/*Bild*/
figure.page-image {margin: 0 0 1.3em 1.3em;}
    
/*Containers*/
.container.sml {width: 96%;}   
   
/*contact*/ 
.contact-section > .container > .twelve:first-of-type {margin-bottom:0; } 
    
/*sektion*/   
.banner-section {background-attachment:initial !important;}  
.banner-section h1 {
    font-size: 3em; margin:0; 
}  
.section.full {min-height:80vw;}  
}


/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{
/*font*/    
h1 {
    font-size: 2em;
}
    
    .referenser .box {flex-basis: 100% !important; }
    
/*Startsida*/ 
.slick-wrapper, .slick-slider, .slick-substitute > div, .slick-slide, .imagetop {
    min-height: 60vw;
}   
.starttext {padding-top:1em; padding-bottom:1em; }
.starttext h1 {font-size:2em;}
.starttext p {
    font-size: 1em; font-weight:300; }
    
/*fixa box-marginaler*/
.margin > .twelve, .margin > .m-twelve, .margin > .s-twelve, .margin > .xs-twelve  {margin: 0 0 1em; flex-basis: 100%;}
    
/*image*/
figure.page_image {margin: 0 0 10px 10px; padding:0; border:none;  }   
    
/*sidfot*/
footer  {font-size: 1.1em; line-height: 1.5; word-spacing: 2pt; padding: 30px 0 60px; }     
    
/*spalt*/    
.spaltX2 {-webkit-column-count: 1; -moz-column-count: 1; column-count: 1; -webkit-column-gap: 0px; -moz-column-gap: 0px; column-gap: 0px; text-align:none;} 

/*gallerithumbs*/
.gallery-image:nth-child(3n+3) {box-shadow: 1px 1px 0 0 #e4e4e4;} 
.gallery-image:nth-child(2n+2) {box-shadow: 0 1px 0 0 #e4e4e4;} 
.gallery-image img {padding: 0.8em;}    
    
/*map*/
.map-canvas {width:100%; max-height:20vh;} 
    
/*sektion*/   
.section {padding: 50px 0; }  
.banner-section h1 {font-size:1.8em;}    
    
    .eio {display:none; }
/*table
table tr td { width:100% !important; margin: 0 !important; padding:0 !important ; display: inline-block;}
table tr:first-child {}
table tr td:first-child {padding-top:5px !important; }
table tr td:last-child {padding-bottom:5px !important; }*/  
}

@media (max-width: 350px)
{
    .box {padding: 10px !important; }  
    .starttext h1 {font-size:1.8em; margin:0; }

 h1, h2, h3, h4, h5, h6 { hyphens: auto;}
    
/*font*/    
h1 {font-size:1.8em !important; }  

/*start*/
.slick-wrapper .starttext p {display:none;} 
    
/*bild*/
figure.page-image {width:100%; max-width:100%; margin: 0 0 1em; float:none; }     
}

/*-------------------------------------------------------------------END MEDIA QUERYS--------------------------------------------------------------------*/





 

/* --------------------------------------------------------Sliding border under text--------------------------------------------------------- 
.box h2 {padding-bottom: 35px; position: relative; text-align: center;}
.box h2:after {content:""; position:absolute; background:#404040; height:3px; width:20px; margin: 45px 0 0; left:50%; transition: width 0.5s ease-in 0s; transition: margin 0.5s ease-in 0s; }
.box:hover h2:after { width:150px;  margin:50px 0 0 -75px; }*/


/* ------------------------------------------------------------Sökning--------------------------------------------------------------------------
.searchbox {}
#searchform {margin:0; }
#searchform input {margin:0; height: 23px; padding: 3px 10px; color:#959595; display: inline-block; }
.search {float: none;padding: 0;}*/

/* ------------------------------------------------------------Egen menu -icon-----------------------------------------------------------------
#menu-icon span {border:none; }
#menu-icon  {background-image: url("/theme/intendit/graphics/mobile-menu-icon.png"); background-position: center right; background-repeat: no-repeat;
background-size: 100% auto; height: 30px; width: 40px;}*/


/*------------------------------------------------------Make FB widget responsive-------------------------------------------------------*/
.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget iframe[style], .twitter-timeline {width: 100% !important;}
